.notfound_body {
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
}

.notfound_body img {
    width: 120px;
}

.notfound_body p {
    font-size: 15px;
}